.app-dashboard{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: blueviolet;
    height: 100vh;
}

.powered-by-section{
    /* position: absolute; */
    right: 100px;
    display: flex;
    align-items: baseline;
}

.logo-footer {
    width: 60px;
    height: auto;
}

.logo-header {
    width: 100px;
    height: auto;
}

/* .dashboard-container {
    overflow: hidden;
} */

.dashboard-content {
    height: 900px;
    width: inherit;
    overflow-y: scroll;
    overflow-x: hidden;
    
    display: flex;
    flex-direction: column;
    align-items: center;
}

.setup-container {
    width: 1100px;
    height: 700px;
    padding: 4px;
    padding-right: 8px;

    overflow-y: auto;
    overflow-x: hidden;
}

.module-section {
    height: 300px;
    width: 400px;
    padding: 8px;
}

